import React, { Component } from "react";
import ReactGA from "react-ga";

ReactGA.initialize("UA-176925647-1");

const GATracker = (WrappedComponent, options = {}) => {
    const trackPage = (page) => {
        ReactGA.set({
            page,
            ...options
        });
        ReactGA.pageview(page);
    };

    const HOC = class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                hash: props.location.pathname,
            };
            trackPage(props.location.pathname);
        }

        // DEPRECATED
        // componentDidMount() {
        //     const {
        //         location: { pathname: page }
        //     } = this.props;
        //     trackPage(page);
        // }

        // componentWillReceiveProps(nextProps) {
        //     const {
        //         location: { pathname: currentPage }
        //     } = this.props;
        //     const nextPage = nextProps.location.pathname;

        //     if (currentPage !== nextPage) {
        //         trackPage(nextPage);
        //     }
        // }

        static getDerivedStateFromProps(nextProps, prevState){
            if (nextProps.location.hash !== prevState.hash) {
                return { hash: nextProps.location.hash };
            }
            else return null; // Triggers no change in the state
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

const Event = (category, action, label) => {
    ReactGA.event({ category, action, label });
};

export {
    GATracker,
    Event,
};