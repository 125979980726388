/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
    isSplash: false, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
    title: "Jackson Yuan",
    logo_name: "JacksonYuan",
    // nickname: "jackson_yuan",
    subTitle: "A passionate and avid Computer Science student who loves building applications on the cloud that help deliver social good.",
    resumeLink: "/resume.pdf",
    // portfolio_repository: "https://github.com/jacksonyuanjx",
};

const socialMediaLinks = [
    {
        name: "Github",
        link: "https://github.com/jacksonyuanjx",
        fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
        backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
    },
    {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/jackson-yuan/",
        fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
        backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
    },
    {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UCbmBrWg8WX80aVa6EBA395A",
        fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
        backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
    },
    {
        name: "Email",
        link: "mailto:jacksonyuanjx@hotmail.com",
        fontAwesomeIcon: "far fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
        backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
    },
];

const skills = {
    data: [
        {
            title: "Full Stack Development",
            fileName: "FullStackImg",
            skills: [
                "⚡ Building highly scalable serverless back-end applications and APIs primarily with Node.js and Express.js",
                "⚡ Developing front-end applications using frameworks & template engines such as React.js and Pug.js",
                "⚡ Maintaining high code coverage using frameworks such as Jest.js, Mocha.js, and JUnit",
            ],
            softwareSkills: [
                {
                    skillName: "HTML5",
                    fontAwesomeClassname: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    fontAwesomeClassname: "fa-css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                // {
                // 	skillName: "Sass",
                // 	fontAwesomeClassname: "simple-icons:sass",
                // 	style: {
                // 		color: "#CC6699",
                // 	},
                // },
                {
                    skillName: "JavaScript",
                    fontAwesomeClassname: "simple-icons:javascript",
                    style: {
                        backgroundColor: "#000000",
                        color: "#F7DF1E",
                    },
                },
                {
                    skillName: "ReactJS",
                    fontAwesomeClassname: "simple-icons:react",
                    style: {
                        color: "#61DAFB",
                    },
                },
                {
                    skillName: "NodeJS",
                    fontAwesomeClassname: "simple-icons:node-dot-js",
                    style: {
                        color: "#339933",
                    },
                },
                {
                    skillName: "MochaJS",
                    fontAwesomeClassname: "simple-icons:mocha",
                    style: {
                        color: "#8D6748"
                    }
                },
                {
                    skillName: "NPM",
                    fontAwesomeClassname: "simple-icons:npm",
                    style: {
                        color: "#CB3837",
                    },
                },
                {
                    skillName: "Yarn",
                    fontAwesomeClassname: "simple-icons:yarn",
                    style: {
                        color: "#2C8EBB",
                    },
                }
                // {
                // 	skillName: "Gatsby",
                // 	fontAwesomeClassname: "simple-icons:gatsby",
                // 	style: {
                // 		color: "#663399",
                // 	},
                // },
                // {
                // 	skillName: "Flutter",
                // 	fontAwesomeClassname: "simple-icons:flutter",
                // 	style: {
                // 		color: "#02569B",
                // 	},
                // },
            ],
        },
        {
            title: "Cloud Infrastructure",
            fileName: "CloudInfraImg",
            skills: [
                "☁️ Experience working on multiple cloud platforms (primarily on AWS)",
                "🌩️ Architecting applications and data pipelines on the cloud",
                "🌨️ Deploying and maintaining websites on VM instances along with database management/integrations",
            ],
            softwareSkills: [
                {
                    skillName: "AWS",
                    fontAwesomeClassname: "simple-icons:amazonaws",
                    style: {
                        color: "#FF9900",
                    },
                },
                {
                    skillName: "Azure",
                    fontAwesomeClassname: "simple-icons:microsoftazure",
                    style: {
                        color: "#0089D6",
                    },
                },
                {
                    skillName: "GCP",
                    fontAwesomeClassname: "simple-icons:googlecloud",
                    style: {
                        color: "#4285F4",
                    },
                },
                {
                    skillName: "Firebase",
                    fontAwesomeClassname: "simple-icons:firebase",
                    style: {
                        color: "#FFCA28",
                    },
                },
                {
                    skillName: "MySQL",
                    fontAwesomeClassname: "simple-icons:mysql",
                    style: {
                        color: "#4479A1",
                    },
                },
                {
                    skillName: "PostgreSQL",
                    fontAwesomeClassname: "simple-icons:postgresql",
                    style: {
                        color: "#336791",
                    },
                },
                {
                    skillName: "MongoDB",
                    fontAwesomeClassname: "simple-icons:mongodb",
                    style: {
                        color: "#47A248",
                    },
                },
                {
                    skillName: "Docker",
                    fontAwesomeClassname: "simple-icons:docker",
                    style: {
                        color: "#1488C6",
                    },
                },
                // {
                // 	skillName: "Kubernetes",
                // 	fontAwesomeClassname: "simple-icons:kubernetes",
                // 	style: {
                // 		color: "#326CE5",
                // 	},
                // },
            ],
        },
        // {
        // 	title: "UI/UX Design",
        // 	fileName: "DesignImg",
        // 	skills: [
        // 		"⚡ Designing highly attractive user interface for mobile and web applications",
        // 		"⚡ Customizing logo designs and building logos from scratch",
        // 		"⚡ Creating the flow of application functionalities to optimize user experience",
        // 	],
        // 	softwareSkills: [
        // 		{
        // 			skillName: "Adobe XD",
        // 			fontAwesomeClassname: "simple-icons:adobexd",
        // 			style: {
        // 				color: "#FF2BC2",
        // 			},
        // 		},
        // 		{
        // 			skillName: "Figma",
        // 			fontAwesomeClassname: "simple-icons:figma",
        // 			style: {
        // 				color: "#F24E1E",
        // 			},
        // 		},
        // 		{
        // 			skillName: "Adobe Illustrator",
        // 			fontAwesomeClassname: "simple-icons:adobeillustrator",
        // 			style: {
        // 				color: "#FF7C00",
        // 			},
        // 		},
        // 		{
        // 			skillName: "Inkscape",
        // 			fontAwesomeClassname: "simple-icons:inkscape",
        // 			style: {
        // 				color: "#000000",
        // 			},
        // 		},
        // 	],
        // },
    ],
};

// Education Page
const competitiveSites = {
    competitiveSites: [
        // {
        // 	siteName: "HackerRank",
        // 	iconifyClassname: "simple-icons:hackerrank",
        // 	style: {
        // 		color: "#2EC866",
        // 	},
        // 	profileLink: "#",
        // },
        // {
        // 	siteName: "Codechef",
        // 	iconifyClassname: "simple-icons:codechef",
        // 	style: {
        // 		color: "#5B4638",
        // 	},
        // 	profileLink: "#",
        // },
        // {
        // 	siteName: "Codeforces",
        // 	iconifyClassname: "simple-icons:codeforces",
        // 	style: {
        // 		color: "#1F8ACB",
        // 	},
        // 	profileLink: "#",
        // },
        // {
        // 	siteName: "Hackerearth",
        // 	iconifyClassname: "simple-icons:hackerearth",
        // 	style: {
        // 		color: "#323754",
        // 	},
        // 	profileLink: "#",
        // },
        // {
        // 	siteName: "Kaggle",
        // 	iconifyClassname: "simple-icons:kaggle",
        // 	style: {
        // 		color: "#20BEFF",
        // 	},
        // 	profileLink: "#",
        // },
    ],
};

const degrees = {
    degrees: [
        {
            title: "The University of British Columbia",
            subtitle: "Bachelor of Science | Computer Science (Grad: May 2022)",
            logo_path: "ubc_logo.png",
            alt_name: "UBC Logo",
            duration: "2017 - Present",
            descriptions: [
                {
                    key: "Relevant Coursework",
                    value: "Software Engineering, Machine Learning & Data Science, Computer Systems, Algorithms & Data Structures, Matrix Algebra, Multivariable Calculus, Statistics & Probability",
                },
                {
                    key: "Academic Awards",
                    value: "Dean’s Honour List",
                },
                {
                    key: "Clubs",
                    value: "UBC Launch Pad - Software Developer",
                },
            ],
            website_link: "https://www.ubc.ca/",
        },
    ],
};

const certifications = {
    certifications: [
        {
            title: "AWS Certified Solutions Architect - Associate",
            logo_path: "aws-saa-badge.png",
            alt_name: "AWS Certified Solutions Architect - Associate",
            certificate_link: "https://www.credly.com/badges/3860e78f-33f1-43ad-90b4-aea468e35d76",
            // color_code: "#8C151599",
        },
        {
            title: "Docker Essentials",
            logo_path: "docker-essentials-badge.png",
            alt_name: "Docker Essentials",
            certificate_link: "https://www.credly.com/badges/a298161d-c949-40f8-bde3-05921ed1b1dc",
            // color_code: "#8C151599",
        },
        {
            title: "Containers & Kubernetes Essentials",
            logo_path: "containers-and-kubernetes-essentials-badge.png",
            alt_name: "Containers & Kubernetes Essentials",
            certificate_link: "https://www.credly.com/badges/8c2fcf29-2e41-4b1f-bdb0-25f52f9151ff",
            // color_code: "#8C151599",
        },
        {
            title: "Machine Learning with Python - Level 1",
            logo_path: "ml-with-python-badge.png",
            alt_name: "Machine Learning with Python - Level 1",
            certificate_link: "https://www.credly.com/badges/8c3d36f2-f1fa-41d4-9802-89502b9a3efb",
            // color_code: "#8C151599",
        },
        {
            title: "Python for Data Science",
            logo_path: "python-for-ds-badge.png",
            alt_name: "Python for Data Science",
            certificate_link: "https://www.credly.com/badges/b6ed98df-d596-496d-a914-a822aa24b194",
            // color_code: "#8C151599",
        },
        {
            title: "AWS Technical Essentials Training",
            logo_path: "aws_technical_essentials_logo.png",
            alt_name: "AWS Technical Essentials",
            // color_code: "#8C151599",
        },
    ],
};

// Experience Page
const experience = {
    title: "Experience",
    subtitle: "Internships & Work", // Work, Internship and Volunteering
    description: "Most of my work experience revolves around full-stack web development, particularly in building scalable applications on the cloud.",
    header_image_path: "experience.svg",
    sections: [
        {
            title: "Internships",
            experiences: [
                {
                    title: "IoT & AI Developer Intern",
                    company: "IBM | Global Business Services",
                    company_url: "https://www.ibm.com/ca-en",
                    logo_path: "ibm-logo.jpeg",
                    duration: "May 2021 - Aug 2021",
                    location: "Remote (based in Toronto, Ontario)",
                    description: [
                        `• Led development of MERN web app for data scientists to annotate images & train models and restructured 
                        backend/frontend data schema which improved code maintainability and reduced page re-renders`,
                        `• Pioneered robotics development on AWS RoboMaker using ROS Melodic, Gazebo, RQT, and Python to build 
                        early-stage simulation of a Boston Dynamics robot with basic autonomous navigation`,
                        `• Developed and deployed internal dashboard using Python Dash, Twitter API, MongoDB, IBM Watson NLU, 
                        and Cloud Foundry to extract, process & present tweet analytics`
                    ],
                    color: "#000",
                },
                {
                    title: "Software Engineer Intern",
                    company: "Realtor.com",
                    company_url: "https://realtor.com",
                    logo_path: "rdc_logo.png",
                    duration: "Sept 2019 - Sept 2020",
                    location: "Richmond, British Columbia",
                    description: [
                        `• Built & maintained highly scalable AWS applications/APIs following serverless architectures, 
                        along with containerized service for managing and delivering millions of consumer notifications/emails`,
                        `• Deployed serverless & containerized applications to AWS using Lambda, SQS, SNS, S3, API Gateway,
                        DynamoDB, ElastiCache, DocumentDB, EventBridge, ECS, and more`,
                        `• Worked closely with marketing teams and product managers to demo and release notifications/emails for 
                        new home-search filters across various channels, which helped increase the accuracy and relevancy of alerts`,
                        `• Worked with the team to conduct code reviews and wrote comprehensive unit tests for every ticket, 
                        which increased code coverage to 90-100% across several managed repositories`
                    ],
                    color: "#ee3c26",
                },
            ],
        },
        {
            title: "Work",
            experiences: [
                {
                    title: "Software Developer - Contractor",
                    company: "FreshOnFood",
                    company_url: "https://freshonfood.com/",
                    logo_path: "fof_logo.png",
                    duration: "June 2020 - July 2020",
                    location: "Vancouver, British Columbia",
                    description: [
                        `• Launched a Flutter webview app on the App Store & Google Play store`,
                        `• Fixed any bugs that arose and helped implement the push notifications feature using FCM for the client`,
                    ],
                    color: "#0879bf",
                },
            ],
        },
        {
            title: "Volunteering",
            experiences: [
                {
                    title: "Software Developer",
                    company: "UBC Launch Pad",
                    company_url: "https://ubclaunchpad.com/",
                    logo_path: "ubc_launchpad_logo.jpg",
                    duration: "Sept 2020 - Present",
                    location: "Vancouver, British Columbia",
                    description: [
                        "• Developed a web platform with a team of 11, using Node.js, Express.js, React.js, and PostgreSQL that helps streamline the course registration process for UBC students",
                        "• Set up CI/CD pipelines to deploy the application to Netlify and Heroku (@ https://course-load.netlify.app)",
                    ],
                    color: "#4285F5",
                }
            ],
        },
    ],
};

// Projects Page
const projectsHeader = {
    title: "Projects",
    description: "In my spare time, I love building applications that help solve a personal or common problem! Recently, I've been building more applications on the cloud. Feel free to check out a few below!",
    avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
    contactSection: {
        title: "Contact Me",
        profile_image_path: "jackson_yuan_profile_img.png",
        description: "Feel free to contact me if you have any inquiries - I'd be more than happy to hear about collaboration/hiring opportunities or just discussing anything tech-related! 😊 I'm also currently looking for internships/full-time positions.",
    },
};

export {
    settings,
    greeting,
    socialMediaLinks,
    skills,
    competitiveSites,
    degrees,
    certifications,
    experience,
    projectsHeader,
    contactPageData,
};
